import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout/layout'
import Title from '../components/title/title'
import ArticleStub from '../components/article-stub/article-stub'

export default () => {
	const data = useStaticQuery(graphql`{
		allMdx(limit: 5, sort: {fields: [frontmatter___date], order: DESC}) {
			edges {
				node {
					id
					fields {
						slug
					}
					frontmatter {
						title
						date(formatString: "MMMM DD, YYYY")
						stub
					}
				}
			}
		}
	}`)
	return (
		<Layout>
			<Title title='Home' />

			<div>
				{data.allMdx.edges.map(({ node }) => (
					<ArticleStub title={node.frontmatter.title} stub={node.frontmatter.stub} date={node.frontmatter.date} slug={node.fields.slug}></ArticleStub>
				))}
			</div>

		</Layout>
	)
}
